/****************** General ***********************/
/****************************************************/
body,
input,
textarea,
select {
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
	margin: 0px;
	padding: 0px;
	line-height: 1.5em;
	color: #000;
	font-size: 18px;
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
u,
ul,
var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	background: 0 0;
	font-size: 100%;
	vertical-align: baseline;
	-webkit-text-size-adjust: 100%;
}

img {
	max-width: 100%;
	height: auto;
}

.container {
	position: relative;
	width: 80%;
	max-width: 1080px;
	margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
	line-height: 1.4em;
	font-family: 'Merriweather', Georgia, "Times New Roman", serif;
}

h1 {
	font-size: 46px;
	font-weight: 500;
	margin-bottom: 15px;
}

h2 {
	font-size: 26px;
	margin-bottom: 15px;
}

h3 {
	font-size: 22px;
	margin-bottom: 10px;
}

h4 {
	font-size: 20px;
	margin-bottom: 10px;
}

h5 {
	font-size: 18px;
	margin-bottom: 10px;
}

h6 {
	font-size: 16px;
	margin-bottom: 10px;
}

p {
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	color: #000;
	margin-bottom: 15px;
}

a {
	color: #408457;
}

a:hover {
	text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	text-decoration: none;
}

ul,
ol {
	margin: 0px 0px 0px 15px;
	padding: 0px 0px 0px;
}

@media (max-width: 960px) {
	h1 {
		font-size: 28px;
	}
}

@media (max-width: 479px) {
	h2 {
		font-size: 16px;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
#main-header,
#top-header {
	position: fixed;
}

#top-header {
	background-color: #408457;
	color: #fff;
	width: 100%;
	top: 0;
	z-index: 1000;
}

#top-header .container {
	position: relative;
	width: 80%;
	max-width: 1200px;
	margin: auto;
}

#top-header .et-social-icons {
	height: 44px;
	margin: 0px;
	padding: 0px;
	list-style: none;
	display: inline-block;
}

#top-header .et-social-icon {
	height: 100%;
	border-right: 1px solid #1d986e;
	margin: 0px;
	padding: 0px;
	display: inline-block;
}

#top-header .et-social-icon:first-child {
	border-left: 1px solid #1d986e;
}

#top-header .et-social-icon a {
	font-size: 20px;
	top: 10px;
	padding: 0px 10px !important;
	display: block;
	line-height: 1em;
	position: relative;
	color: #fff;
	text-align: center;
	text-decoration: none;
	transition: color 0.3s ease 0s;
}

#top-header .et-social-icon.et-social-facebook a {
	padding: 0px 12px !important;
}

#top-header .et-social-icon.et-social-facebook a:before {
	font-size: 16px;
	font-weight: 400;
	font-family: FontAwesome;
	content: "\f09a";
}

#top-header .et-social-icon.et-social-twitter a:before {
	font-size: 16px;
	font-weight: 400;
	font-family: FontAwesome;
	content: "\f099";
}

#top-header .et-social-icon.et-social-linkedIn a:before {
	font-size: 16px;
	font-weight: 400;
	font-family: FontAwesome;
	content: "\f0e1";
}

#top-header .et-social-icon.et-social-instagram a:before {
	font-size: 16px;
	font-weight: 400;
	font-family: FontAwesome;
	content: "\f16d";
}

.top-navigation-container {
	display: inline;
}

#top-header #et-secondary-nav {
	float: right;
	height: 44px;
	margin: 0px;
	padding: 0px;
}

#et-secondary-nav,
#et-secondary-nav li {
	display: inline-block;
	height: 100%;
}

#top-header li,
#top-header li a,
#top-header .et-social-icons {
	margin: 0px !important;
	padding: 0px !important;
}

#et-secondary-nav li {
	position: relative;
	text-align: right;
}

#et-secondary-nav li {
	margin-right: 15px;
}

#et-secondary-nav,
#et-secondary-nav li {
	display: inline-block;
}

#et-secondary-menu li,
#top-menu li {
	word-wrap: break-word;
}

#top-header #et-secondary-nav a {
	font-size: 14px;
	font-weight: normal;
	padding: 15px !important;
	text-decoration: none;
	color: #fff;
	line-height: 14px;
	display: inline-block;
	border-left: 1px solid #1d986e;
	font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}

#main-header {
	z-index: 99999;
	top: 44px;
	width: 100%;
	background-color: #fff;
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	font-weight: 500;
	line-height: 23px;
}

#main-header .container {
	max-width: 1200px;
}

.et_header_style_left .logo_container {
	position: absolute;
	width: 100%;
	height: 100%;
}

.logo_container {
	font-size: 28px;
}

span.logo_helper {
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

#logo {
	display: inline-block;
	float: none;
	max-height: 54%;
	margin-bottom: 0;
	vertical-align: middle;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transform: translate3d(0, 0, 0);
}

#et-top-navigation {
	float: right;
	display: inline-block;
}

#top-menu,
.fullwidth-menu,
nav#top-menu-nav,
nav.fullwidth-menu-nav {
	float: left;
	margin-top: -2px;
	margin-right: 1px;
}

#top-menu li {
	display: inline-block;
	padding-right: 22px;
	font-size: 14px;
}

#top-menu > li:last-child {
	padding-right: 0;
}

#top-menu a {
	display: block;
	position: relative;
	color: rgba(0, 0, 0, 0.6);
	text-decoration: none;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	color: #000;
	font-size: 18px;
}

#top-menu a.active {
	color: #0e7330;
}

.logo_container {
	display: inline-block;
}

#et-info-email:hover,
#et-secondary-menu > ul > li > a:hover,
#top-menu-nav > ul > li > a:hover,
.et-social-icons a:hover {
	opacity: 0.7;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

#logo {
	height: 114px;
}

.mobile_menu_bar {
	display: block;
	width: 32px;
	height: 32px;
	padding-top: 45px;
	display: none;
	background-image: url("/20150810114905/assets/images/menu-icon.jpg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

#top-header #et-secondary-nav a:hover {
	opacity: 0.7;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

#main-header #et-top-navigation {
	transition: all 0.4s ease-in-out;
}

@media only screen and (min-width: 981px) {
	#logo {
		max-height: 100%;
	}

	#et-top-navigation {
		padding: 50px 0 0 0;
	}

	#main-header.sticky #top-menu li a {
		font-size: 16px !important;
		font-weight: normal;
	}

	#main-header.sticky #et-top-navigation {
		padding: 30px 0 0 0;
	}

	#main-header.sticky #logo {
		height: 54px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

@media screen and (max-width: 1270px) {
	#main-header .container {
		width: 90% !important;
	}

	#logo {
		height: auto;
		max-width: 200px !important;
	}

	#top-menu-nav a {
		font-size: 14px !important;
	}

	.logo_container {
		padding-top: 16px;
		padding-bottom: 10px;
	}

	#main-header.sticky .logo_container {
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

@media ( max-width: 980px ) {
	#top-header {
		display: none;
	}

	.et_header_style_left #logo,
	.et_header_style_split #logo {
		max-width: 50%;
	}

	#logo,
	#main-header,
	.container,
	.logo_container {
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
	}

	#top-menu {
		display: none;
	}

	.et_header_style_left #et-top-navigation,
	.et_header_style_left .et-fixed-header #et-top-navigation {
		display: block;
		padding-top: 24px;
	}

	#main-header,
	#top-header {
		position: static;
	}

	#logo {
		max-width: 108px !important;
		height: auto;
	}

	.logo_container {
		padding-bottom: 18px;
		padding-top: 18px;
	}

	.mobile_menu_bar {
		display: block;
	}

	#main-header #et-top-navigation {
		transition: none;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	margin-top: 81px;
	background-color: #fff;
	border-top: 3px solid #408457;
}

.mobile-navigation-menu li .nav-a {
	font-size: 14px;
	color: #000000;
	padding-bottom: 12px;
	padding-top: 12px;
}

.mobile-navigation-menu.mm-menu .mm-navbar a,
.mobile-navigation-menu.mm-menu .mm-navbar > * {
	font-size: 14px;
	color: #000000;
	text-align: left;
	padding-top: 13px;
}

.mobile-navigation-menu .mm-navbar {
	height: 45px;
}

.mobile-navigation-menu li .nav-a:hover,
.mobile-navigation-menu li .nav-a.active {
	opacity: 0.7;
	background-color: rgba(0, 0, 0, 0.03);
}

.mobile-navigation-menu .mm-listitem {
	position: relative;
	margin-right: 20px;
	margin-left: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.mobile-navigation-menu .mm-listitem:after {
	border-bottom: 0px;
}

.mobile-navigation-menu .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mobile-navigation-menu .mm-btn_prev:before {
	left: 18px;
	top: 5px;
}

/******************** Homepage **********************/
/*****************************************************/

/********************** Slideshow *************************/
/**********************************************************/

/****************** Inside Page *******************/
/*************************************************/
.main-content-container {
	min-height: 500px;
	max-width: 1200px;
	margin: 0 auto;
	width: 80%;
	margin-top: 210px;
	padding-bottom: 50px;
}

.left-col {
	width: 25%;
	margin-bottom: 40px;
	float: left;
}

.right-col {
	margin-bottom: 40px;
	width: 25%;
	float: right;
}

.main-col {
	margin-bottom: 40px;
}

.main-col.no-sidebars {
	width: 100%;
}

.main-col.left-column-sidebar {
	width: 70%;
	float: right;
}

.main-col.right-column-sidebar {
	width: 70%;
	float: left;
}

.main-col.left-right-sidebars {
	width: 46%;
	padding-left: 2%;
	padding-right: 2%;
	float: left;
}

.left-nav-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.left-nav-list li {
	margin: 0px;
	padding: 0px;
}

.left-nav-list li a {
	font-family: 'Merriweather', Georgia, "Times New Roman", serif;
	font-size: 16px;
	font-weight: 100;
	line-height: normal;
	background: #e2e4e7;
	display: block;
	margin: 0;
	padding: 20px 20px;
	border-bottom: 1px solid #fff;
	text-decoration: none;
	color: #666;
}

.left-nav-list li a:hover,
.left-nav-list li a.active {
	font-weight: bold;
	color: #fff;
	background: #333;
}

.left-nav-list li li a {
	padding: 16px 40px;
	font-size: 14px;
}

.left-nav-list li li li a {
	padding: 16px 60px;
	font-size: 13px;
}

.left-nav-list li li a:hover,
.left-nav-list li li a.active {
	background: #454a50;
}

.left-navigation {
	margin-bottom: 20px;
}

@media (max-width: 980px) {
	.main-content-container {
		margin-top: 40px;
	}

	.left-col {
		display: none;
	}

	.main-col,
	.right-col,
	.main-col.no-sidebars,
	.main-col.left-column-sidebar,
	.main-col.right-column-sidebar,
	.main-col.left-right-sidebars {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.right-col {
		padding-top: 20px;
		border-top: 1px solid #ccc;
	}
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.main-content-container {
		width: 95%;
	}
}


/******************* Right Sidebar *******************/
/*****************************************************/
.right-col .box {
	margin-bottom: 2rem;
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-col .box:before {
	width: 50px;
	height: 4px;
	margin-top: 0.5rem;
	background-color: #408457;
	content: "";
	display: block;
	margin-bottom: 1.25rem;
}

.right-col .box h4,
.right-col .box h3 {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-size: 2rem;
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-col .box p {
	font-size: 1rem;
	line-height: 1.45rem;
}

.right-col .box ul {
	font-size: 1rem;
	line-height: 1.45rem;
}

.right-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-col .box .contentbox_item_image {
	width: 24px;
}

.right-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-col.box.tools td:first-child img {
	margin-right: 5px;
}



/*********************** Footer **********************/
/*****************************************************/
#main-footer {
	display: inline-block;
	width: 100%;
	background-color: #35373a;
	float: left;
}

#main-footer .container {
	max-width: 1200px;
}

#footer-widgets {
	padding: 6% 0 0;
	display: inline-block;
	width: 100%;
}

#main-footer #media_image-2 {
	margin-bottom: 20px;
}

#footer-widgets .footer-widget li {
	position: relative;
	padding: 0px;
	padding-left: 14px;
	margin: 0px;
	margin-bottom: 0.5em;
	font-size: 16px;
	font-weight: 300;
	line-height: normal;
}

#main-footer .footer-contact {
	margin-left: 25px;
	list-style: none;
	margin: 0px;
	padding: 0px;
}

#main-footer .footer-contact i {
	font-family: FontAwesome;
	font-style: inherit;
	left: -10px;
	float: left;
	margin-bottom: 8px;
	position: relative;
	top: 3px;
}

#main-footer .star-logo {
	margin: 5px auto;
	display: block !important;
	max-width: 100px;
}

#main-footer .footer-logo {
	max-width: 100%;
	height: auto;
}

#main-footer .et_pb_widget {
	float: left;
	max-width: 100%;
}

#main-footer .footer-widget {
	color: #ffffff;
}

#footer-bottom {
	padding: 15px 0 5px;
	background-color: #35373a;
}

#footer-bottom > .container {
	border-top: 1px solid #545658;
	padding-top: 30px;
	padding-bottom: 50px;
	max-width: 1200px;
}

#footer-info {
	font-size: 16px;
	color: #ffffff;
	padding-bottom: 10px;
	font-weight: 300;
	float: left;
	line-height: 27px;
}

#footer-info a,
#footer-info a:hover {
	color: #ffffff;
	font-weight: 300;
	line-height: 27px;
	text-decoration: none;
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px;
}

#main-footer .et-social-icons {
	float: right;
}

#footer-bottom .et-social-icon {
	margin: 0px;
	display: inline-block;
}

#footer-bottom .et-social-icon a {
	color: #ffffff;
	font-size: 22px;
	border: 1px solid #545658;
	padding: 8px 10px;
	position: relative;
	top: -5px;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	width: 20px;
	transition: all 0.4s ease-in-out;
}

#main-footer .et-social-facebook a.icon:before {
	font-family: FontAwesome;
	content: "\f09a";
}

#main-footer .et-social-twitter a.icon:before {
	font-family: FontAwesome;
	content: "\f099";
}

#main-footer .et-social-linkedin a.icon:before {
	font-family: FontAwesome;
	content: "\f0e1";
}

#main-footer .et-social-instagram a.icon:before {
	font-family: FontAwesome;
	content: "\f16d";
}

#footer-widgets .footer-widget {
	float: left;
	color: #fff;
	display: inline-block;
}

.footer-widget .fwidget:last-child {
	margin-bottom: 0 !important;
}

.phone-desktop {
	display: inline;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
}

.footer-contact a {
	color: #fff;
	text-decoration: none;
}

.login-container {
	display: inline;
}

@media (min-width: 981px) {
	#footer-widgets .footer-widget {
		width: 20.875%;
		margin-right: 5.5%;
		margin-bottom: 5.5%;
		color: #fff;
		display: inline-block;
	}

	.footer-widget .fwidget {
		margin-bottom: 26.347%;
	}

	.footer-widget:last-child {
		margin-right: 0 !important;
	}
}

@media (max-width: 980px) {
	#footer-widgets .footer-widget:nth-last-child(-n+2) {
		margin-bottom: 0 !important;
	}

	#footer-widgets {
		padding: 8% 0;
	}

	.footer-widget:nth-child(n) {
		width: 46.25% !important;
		margin: 0 7.5% 7.5% 0 !important;
	}

	#footer-widgets .footer-widget .fwidget {
		margin-bottom: 16.21%;
	}

	#footer-info,
	.et-social-icons {
		float: none;
		text-align: center;
	}

	#footer-info,
	.et-social-icons {
		float: none;
		list-style-type: none;
		text-align: center;
	}

	#main-footer .et-social-icons {
		margin: 0 0 5px;
	}

	#main-footer .et-social-icons {
		float: none;
	}

	#footer-widgets .footer-widget:nth-child(2n) {
		margin-right: 0 !important;
	}

	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline;
	}
}

.footer-list-container ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#footer-widgets .footer-widget .footer-list-container {
	color: #ffffff;
	font-size: 16px;
	font-weight: 300;
}

#footer-widgets .footer-widget .footer-list-container ul li {
	position: relative;
	margin: 0px;
	padding: 0px;
	padding-left: 14px;
	margin-bottom: 6px !important;
	color: #ffffff;
	font-size: 16px;
	font-weight: 300;
}

#footer-widgets .footer-widget .footer-list-container ul li strong {
	font-weight: bold;
	font-size: 18px;
}

#footer-widgets .footer-widget .footer-list-container ul li a,
#footer-widgets .footer-widget .footer-list-container ul li a:hover {
	color: #ffffff;
	font-weight: 300;
	line-height: 24px;
	text-decoration: none;
}

#footer-widgets .ic-envelope {
	display: inline-block;
	width: 16px;
	height: 12px;
	background-image: url("/20150810114905/assets/images/ic-envelope.jpg");
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 8px;
	float: left;
	margin-left: -24px;
	position: relative;
	margin-top: 6px;
	margin-right: 8px;
}

#footer-widgets .ic-phone {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-image: url("/20150810114905/assets/images/ic-phone.jpg");
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 8px;
	float: left;
	margin-left: -24px;
	position: relative;
	margin-top: 6px;
	margin-right: 8px;
}

#footer-widgets .ic-marker {
	display: inline-block;
	width: 16px;
	height: 18px;
	background-image: url("/20150810114905/assets/images/ic-marker.jpg");
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 8px;
	float: left;
	margin-left: -24px;
	position: relative;
	margin-top: 6px;
	margin-right: 8px;
}

@media screen and (max-width: 768px) {
	#main-footer .footer-widget {
		width: 100% !important;
		float: none;
		overflow: auto;
	}

	#main-footer .footer-widget:first-child > div {
		width: 100%;
	}
}

@media (max-width: 767px) {
	#footer-widgets {
		padding: 10% 0;
	}

	#footer-widgets .footer-widget,
	.et_pb_gutters1 #footer-widgets .footer-widget {
		width: 100% !important;
		margin-right: 0 !important;
	}

	#footer-widgets .footer-widget .fwidget,
	#footer-widgets .footer-widget:nth-child(n) {
		margin-bottom: 9.5% !important;
	}

	#footer-widgets .footer-widget:last-child {
		margin-bottom: 0 !important;
	}

	#footer-widgets .footer-widget .fwidget:last-child {
		margin-bottom: 0 !important;
	}

	#footer-widgets .ic-envelope,
	#footer-widgets .ic-marker,
	#footer-widgets .ic-phone {
		margin-left: 0px;
	}
}

@media screen and (max-width: 480px) {
	.footer-widget #media_image-2 {
		max-width: 150px;
		margin: 0 auto;
		float: none;
	}
}

@media (max-width: 479px) {
	#footer-widgets {
		padding: 12% 0;
	}

	#footer-widgets .footer-widget:nth-child(n),
	.footer-widget .fwidget {
		margin-bottom: 11.5% !important;
	}

	#footer-widgets .footer-widget:nth-child(n),
	.footer-widget .fwidget {
		margin-bottom: 11.5% !important;
	}
}


/****************** Inside Page Styles ***************/
/*****************************************************/
/* buttons */
.button,
.primary,
.home-section-outer-wrap .primary,
.cms_form_button.primary,
button[type="submit"],
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.secondary,
.home-section-outer-wrap .secondary,
.cms_form_button.secondary,
.tertiary,
.home-section-outer-wrap .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 0.6rem 1rem !important;
	border: 2px solid #408457 !important;
	border-radius: 8px;
	background-color: #408457;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff !important;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.03rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	box-shadow: none;
}

.button:hover,
.primary:hover,
.home-section-outer-wrap .primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.secondary:hover,
.home-section-outer-wrap .secondary:hover,
.cms_form_button.secondary:hover,
.tertiary,
.home-section-outer-wrap .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	border-width: 2px;
	border-color: #408457;
	background-color: #fff;
	box-shadow: none;
	color: #408457 !important;
	box-shadow: none;
}

.secondary,
.home-section-outer-wrap .secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	border-color: #636466 !important;
	background-color: #636466;
	color: #fff !important;
}


.secondary:hover,
.home-section-outer-wrap .secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #fff;
	color: #636466 !important;
	border-color: #636466 !important;
}

.tertiary,
.home-section-outer-wrap .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.4rem 0.5rem !important;
	border: 2px solid #231f20 !important;
	background-color: #231f20;
	font-size: 0.85rem;
	line-height: 1rem;
	color: #fff !important;
}

.tertiary:hover,
.home-section-outer-wrap .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	border: 2px solid #231f20 !important;
	background-color: #fff;
	color: #231f20 !important;
}


/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #1ba53c;
	background-color: rgba(27, 165, 60, 0.2);
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #e70417;
	background-color: rgba(253, 54, 54, 0.15);
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.success *,
#message.error * {
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

#message ul {
	list-style-type: none;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(99, 100, 102, 0.5);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	font-size: 1.1rem;
	line-height: 1.4rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #408457;
	color: #fff;
}

table.styled th {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #c6c6c6;
	color: #000;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #e5e5e5;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
select,
textarea {
	max-width: 100%;
	padding: 6px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

textarea {
	resize: vertical;
}

label,
.cms_label {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin: 0 0 1rem 0;
	border-left: 5px solid #408457;
	padding: 1rem 1.5rem;
	color: #696b6d;
	font-size: 1.35rem;
	background-color: #efefef;
	line-height: 2rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}


/*********************** Modules ********************/
/****************************************************/

/***************** Content Boxes ********************/
/****************************************************/
